import i18n from "i18next";
import { initReactI18next } from 'react-i18next';

import translationEN from './lang.en.json';
import translationKO from "./lang.ko.json";

const resource = {
    en: {
        translations: translationEN
    },
    ko: {
        translations: translationKO
    },
};

i18n
    .use(initReactI18next)
    .init({
        resources:resource,
        lng: "en",
        fallbackLng: "en",
        debug: true,
        defaultNS: "translations",
        ns: "translations",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false
        }
    });

    export default i18n;