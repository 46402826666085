import '../css/styles.css';

const Mast = () => {
    return(
        <header className="masthead" id="page-top">
            <div className="container">
                <div className="masthead-subheading"> </div>
                <div className="masthead-heading text-uppercase"> </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                {/* <a className="btn btn-primary btn-xl text-uppercase" href="#services">Tell Me More</a> */}
            </div>
        </header>
    )
}

export default Mast;