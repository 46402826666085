import '../css/styles.css';
import './component/button.css';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Nav from './Nav';
import Footer from './Footer';
import StanfordImg from './StanfordImg';
import { Download } from './component/Download';

const Booking = () => {

    return(
        <>
        <Nav />
        <header className="masthead_st">
            <div className = "container">
                <div className = "masthead-subheading"></div>
                <div className = "masthead-heading text-uppercase">CARAVAN X STANFORD</div>
            </div>
        </header>
            <section className = "page-section">
            <div className ="text-center">
                <div className = "hr-sect">
                    <h3 className = "text-uppercase">스탠포드 & 카라반</h3>
                </div>
                <h3 className = "text-muted">스탠포드 재학생들과 함께하는 청소년 리서치 캠프</h3>
                {/* 모집공고, 지원서, 안내책자 다운 세개 버튼 필요 */}
                <button className = "button" 
                    style = {{ width: '350px', height: '50px' }}
                    onClick = {() => {
                        Download("Stanford_notice.pdf", "청소년 리서치 캠프 포스터.pdf");
                    }}>
                    <div className = "text-center">
                        청소년 리서치 캠프 포스터 다운로드
                    </div>
                </button>
                <button className = "button" 
                    style = {{ width: '350px', height: '50px' }}
                    onClick = {() => {
                        Download("모집요강(배포용).pdf", "청소년 리서치 캠프 모집 공고.pdf");
                }}>
                    <div className = "text-center">
                        청소년 리서치 캠프 모집요강 다운로드
                    </div>
                </button>
                <button className = "button" 
                    style = {{ width: '350px', height: '50px' }}
                    onClick = {() => {
                        Download("카라반_&_스탠포드_지원서.docx", "카라반_&_스탠포드_지원서.docx");
                    }}>
                    <div className = "text-center">
                        청소년 리서치 캠프 지원서 다운로드
                    </div>
                </button>
                <div className = "ImageSection">
                        <img style={{width: '50%'}} src = {`${process.env.PUBLIC_URL}/assets/img/stanford/Stan_10.png`} alt = "..."/>
                        <div style={{margin: '50px'}}></div>
                        <img style={{width: '50%'}} src = {`${process.env.PUBLIC_URL}/assets/img/stanford/Stan_11.png`} alt = "..."/>
                        <div style={{margin: '50px'}}></div>
                        <img style={{width: '50%'}} src = {`${process.env.PUBLIC_URL}/assets/img/stanford/Stan_12.png`} alt = "..."/>
                        <div style={{margin: '50px'}}></div>
                        <img style={{width: '50%'}} src = {`${process.env.PUBLIC_URL}/assets/img/stanford/Stan_13.png`} alt = "..."/>
                        <div style={{margin: '50px'}}></div>
                        <img style={{width: '50%'}} src = {`${process.env.PUBLIC_URL}/assets/img/stanford/Stan_0.png`} alt = "..."/>
                        <div style={{margin: '50px'}}></div>
                        <img style={{width: '50%'}} src = {`${process.env.PUBLIC_URL}/assets/img/stanford/Stan_1.jpg`} alt = "..."/>
                        <div style={{margin: '50px'}}></div>
                        <img style={{width: '50%'}} src = {`${process.env.PUBLIC_URL}/assets/img/stanford/Stan_2.jpg`} alt = "..."/>
                        <div style={{margin: '50px'}}></div>
                        <img style={{width: '50%'}} src = {`${process.env.PUBLIC_URL}/assets/img/stanford/Stan_5.jpg`} alt = "..."/>
                        <div style={{margin: '50px'}}></div>
                        <img style={{width: '50%'}} src = {`${process.env.PUBLIC_URL}/assets/img/stanford/Stan_6.jpg`} alt = "..."/>
                        <div style={{margin: '50px'}}></div>
                        <img style={{width: '50%'}} src = {`${process.env.PUBLIC_URL}/assets/img/stanford/Stan_9.jpg`} alt = "..."/>
                        <div style={{margin: '50px'}}></div>
                    <div style={{margin: '50px'}}>
                        <StanfordImg />
                    </div>
                </div>
            </div>
            </section>
            <Footer />
        </>
    )
}

export default Booking;