import '../../css/styles.css';
import { useTranslation } from 'react-i18next';
import i18next from "../../i18n";

const NavT = () => {
    const { t } = useTranslation();
    const clickHandler = (lang: string) => {
        i18next.changeLanguage(lang);
    }

    return (  
        <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
            <div className="container">
                <a className="navbar-brand" href="/#page-top"><img src= "/caravan.svg" alt="..." style={{width: '70px', height: '70px', marginBottom: '-40px', marginTop: '-50px'}}/></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    Menu
                    <i className="fas fa-bars ms-1"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
                        <li className="nav-item" ><a className="nav-link" style = {{color:"black"}} href="/#services">{t("Services")}</a></li>
                        <li className="nav-item" ><a className="nav-link" style = {{color:"black"}} href="/#portfolio">{t("Portfolio")}</a></li>
                        <li className="nav-item" ><a className="nav-link" style = {{color:"black"}} href="/#about">{t("About")}</a></li>
                        <li className="nav-item" ><a className="nav-link" style = {{color:"black"}} href="/team">{t("Team")}</a></li>
                        <li className="nav-item" ><a className="nav-link" style = {{color:"black"}} href="/stanford">{t("Stanford Camp")}</a></li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#lang" role="button" data-bs-toggle="dropdown" aria-expanded="false" style = {{color:"black"}}><img className="lang-color" src="/lang.svg" id = "icon" alt="..."/></a>
                            <ul className="dropdown-menu">
                                <li className="nav-item"><button className="nav-link btn-style" style={{color: "black"}} onClick={() =>clickHandler("ko")}>KOR</button></li>
                                <li className="nav-item"><button className="nav-link btn-style" style={{color: "black"}} onClick={() =>clickHandler("en")}>ENG</button></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavT;