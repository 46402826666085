import "../css/styles.css";

const Footer = () => {
    return(
        <footer className="footer py-4" style={{backgroundColor: 'black', color: 'white'}}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 text-lg-start">
                        <div>카라반 리서치</div>
                        <div>사업자 등록번호: 586-21-01959 | 대표: 김성환</div>
                        <div>E-mail: caravanresearchteam@gmail.com</div>
                        <div>Copyright &copy; Caravan Research 2024. All rights reserved.</div>
                    </div>
                    <div className="col-lg-4 my-3 my-lg-0">
                        
                    </div>
                    <div className="col-lg-4 text-lg-end">
                        <a className="btn btn-dark btn-social mx-2" href="https://www.instagram.com/caravanresearch?igsh=MWxpYnRqeDY3ZTJuMg==" aria-label="Facebook"><i className="fab fa-instagram"></i></a>
                        <a className="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/company/caravan-research" aria-label="LinkedIn"><i className="fab fa-linkedin-in"></i></a>
                        <div style={{marginTop: '10px'}}>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;