import '../css/styles.css';
import PostBox from './component/PostBox';
import { useTranslation } from 'react-i18next';

const Portfolio = () => {
    const { t } = useTranslation();
    return(
        <section className="page-section bg-light" id="portfolio">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">{t("Portfolio")}</h2>
                    <h3 className="section-subheading text-muted"></h3>
                </div>
                <div className="row">
                    <PostBox />
                </div>
            </div>
        </section>
    )
}

export default Portfolio;