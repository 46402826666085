import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import '../css/styles.css';
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';

const ActivityPhoto = () => {

    return (
        <div className="ActivityPhoto">
            <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={3}
        coverflowEffect={{
          rotate: 10,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[Autoplay, EffectCoverflow, Pagination ]}
        autoplay={{
            delay: 3000,
            disableOnInteraction: false,
        }}
        className="mySwiper">
                    <SwiperSlide>
                    <img className="imgSize" src={`${process.env.PUBLIC_URL}/assets/img/stanford/Stan_1.jpg`} alt="..."/>
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="imgSize" src={`${process.env.PUBLIC_URL}/assets/img/stanford/Stan_2.jpg`} alt="..."/>
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="imgSize" src={`${process.env.PUBLIC_URL}/assets/img/stanford/Stan_3.jpg`} alt="..."/>
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="imgSize" src={`${process.env.PUBLIC_URL}/assets/img/stanford/mt.jpg`} alt="..."/>
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="imgSize" src={`${process.env.PUBLIC_URL}/assets/img/stanford/Stan_4.jpg`} alt="..."/>
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="imgSize" src={`${process.env.PUBLIC_URL}/assets/img/stanford/Stan_5.jpg`} alt="..."/>
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="imgSize" src={`${process.env.PUBLIC_URL}/assets/img/stanford/Stan_6.jpg`} alt="..."/>
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="imgSize" src={`${process.env.PUBLIC_URL}/assets/img/stanford/Stan_7.jpeg`} alt="..."/>
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="imgSize" src={`${process.env.PUBLIC_URL}/assets/img/stanford/Stan_8.jpg`} alt="..."/>
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="imgSize" src={`${process.env.PUBLIC_URL}/assets/img/stanford/Stan_9.jpg`} alt="..."/>
                    </SwiperSlide>
            </Swiper>
        </div>
    )
}

export default ActivityPhoto;