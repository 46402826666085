import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Nav from './Nav.tsx';
import Mast from './Mast.tsx';
import Service from './Service.tsx';
import Portfolio from './Portfolio.tsx';
import About from './About.tsx';
import Footer from './Footer.tsx';
import Booking from './Booking.tsx';
import ActivityPhoto from './ActivityPhoto.tsx';

const Home = () => {
  return (
    <>
      <Nav />
      <Mast />
      <Service />
      <Portfolio />
      <ActivityPhoto />
      <About />
      <Footer />
    </>
  );
}

export default Home;