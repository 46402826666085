import '../css/styles.css';
import { useTranslation } from 'react-i18next';
import NavT from './component/NavT';

import team from "../teamcontent.json";

const copyClip = (text: string) => {
    try {
        navigator.clipboard.writeText(text);
        alert('Copied!');
    }catch(error){
        alert('Failed!');
    }
}

const Team = () => {
    const { t } = useTranslation();
    return(
        <body className = "none-white">
            <NavT />
            <section className="page-section bg-light" id="team">
                <div className="container">
                    <div className="row">
                    <div className="text-center">
                        <h1 className="section-heading text-uppercase">{t("Team Mates")}</h1>
                        <h3 className="section-heading text-uppercase title-padding">{t("Manager")}</h3>
                        <br />
                    </div>
                    {
                        team.filter(mates=> mates.prior === "1").map(mates => (
                            <>
                                <div className = "col-lg-4">
                                    <div className="team-member">
                                    <section className = "fixed-box2 team-member">
                                        <img className="mx-auto rounded-circle" src={`${process.env.PUBLIC_URL}/assets/img/team/${mates.img}`} alt="..." />
                                        
                                        <h4>{t(mates.name)}</h4>
                                        <p className="text-muted">{t(mates.role)}</p>
                                        {mates.activ.map(act=>(
                                            <p className = "text-body">{t(act)}</p>
                                        ))}
                                        </section>
                                            <button className="btn btn-dark btn-social mx-2" aria-label="Diana Petersen Twitter Profile" onClick={() => copyClip(mates.url[0])}><i className="fab fa-google"></i></button>
                                            <a className="btn btn-dark btn-social mx-2" aria-label="Diana Petersen LinkedIn Profile" 
                                                href = {mates.url[1]} target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
                                        </div>
                            </div>
                        </>
                        ))
                    }
                    <div className="text-center">
                        <h3 className="section-heading text-uppercase title-padding">Caravan X Stanford</h3>
                    </div>
                    {
                        team.filter(mates=> mates.prior === "2").map(mates => (
                            <>
                                <div className = "col-lg-3">
                                    <div className="team-member">
                                        <section className = "fixed-box team-member">
                                        <img className="mx-auto rounded-circle" src={`${process.env.PUBLIC_URL}/assets/img/team/${mates.img}`} alt="..." />
                                        <h4>{t(mates.name)}</h4>
                                        <p className="text-muted">{t(mates.role)}</p>
                                        {mates.activ.map(act=>(
                                            <p className = "text-body">{t(act)}</p>
                                        ))}
                                        </section>
                                        <button className="btn btn-dark btn-social mx-2" aria-label="Diana Petersen Twitter Profile" onClick={() => copyClip(mates.url[0])}><i className="fab fa-google"></i></button>
                                            <a className="btn btn-dark btn-social mx-2" aria-label="Diana Petersen LinkedIn Profile" 
                                                href = {mates.url[1]} target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
                                    </div>
                                </div>
                        </>
                        ))
                    }
                    <div className="text-center">
                        <h3 className="section-heading text-uppercase title-padding">Researchers</h3>
                    </div>
                    {
                        team.filter(mates=> mates.prior === "3").map(mates => (
                            <>
                                <div className = "col-lg-3">
                                    <div className="team-member">
                                    <section className = "fixed-box team-member">
                                        <img className="mx-auto rounded-circle" src={`${process.env.PUBLIC_URL}/assets/img/team/${mates.img}`} alt="..." />
                                        <h4>{t(mates.name)}</h4>
                                        <p className="text-muted">{t(mates.role)}</p>
                                        {mates.activ.map(act=>(
                                            <p className = "text-body">{t(act)}</p>
                                        ))}
                                        </section>
                                        <button className="btn btn-dark btn-social mx-2" aria-label="Diana Petersen Twitter Profile" onClick={() => copyClip(mates.url[0])}><i className="fab fa-google"></i></button>
                                            <a className="btn btn-dark btn-social mx-2" aria-label="Diana Petersen LinkedIn Profile" 
                                                href = {mates.url[1]} target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </>
                        ))
                    }
                     </div>
                </div>
            </section>
        </body>
    )
}

export default Team;