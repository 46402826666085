import '../../css/styles.css';
import data from '../../content.json';
import { useTranslation } from 'react-i18next';

import Modal from './Modal';

export default function PostBox(){
    const { t } = useTranslation();

    return(
        <>
        {
            data.map(datum=>(
                <div className="col-lg-4 col-sm-6 mb-4" key = {datum.title}>
                    <div className="portfolio-item">
                        <a className="portfolio-link" data-bs-toggle="modal" href={`${"#"+datum.path}`}>
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x"></i></div>
                            </div>
                            <img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/img/portfolio/${datum.img}`} alt="..." />
                        </a>
                        <div className="portfolio-caption">
                            <div className="portfolio-caption-heading">{t(datum.title)}</div>
                        </div>
                    </div>
                </div>
            ))
        }
        <Modal />
        </>
    )
}