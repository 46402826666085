import '../css/styles.css';
import { useTranslation } from 'react-i18next';

const Service = () => {
    const { t } = useTranslation();

    return(
        <section className="page-section" id="services">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">{t("Services")}</h2>
                    <h3 className="section-subheading text-muted"></h3>
                </div>
                <div className="row text-center">
                    <div className="col-md-4">
                        <span className="fa-stack fa-4x">
                            <i className="fas fa-circle fa-stack-2x text-primary"></i>
                            <i className="fas fa-solid fa-tree fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 className="my-3">{t("Services1")}</h4>
                        <p className="text-muted">{t("Services1_explain")}</p>
                    </div>
                    <div className="col-md-4">
                        <span className="fa-stack fa-4x">
                            <i className="fas fa-circle fa-stack-2x text-primary"></i>
                            <i className="fas fa-flask fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 className="my-3">{t("Services2")}</h4>
                        <p className="text-muted">{t("Services2_explain")}</p>
                    </div>
                    <div className="col-md-4">
                        <span className="fa-stack fa-4x">
                            <i className="fas fa-circle fa-stack-2x text-primary"></i>
                            <i className="fas fa-user-tie fa-stack-1x fa-inverse"></i>
                        </span>
                        <h4 className="my-3">{t("Services3")}</h4>
                        <p className="text-muted">{t("Services3_explain")}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Service;