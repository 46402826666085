import '../../css/styles.css';
import data from '../../content.json';
import { useTranslation } from 'react-i18next';

export default function Modal(){
    const { t } = useTranslation();

    return(
        <>
        {
            data.map(datum => (
                        <div className="portfolio-modal modal fade" id={datum.path} role="dialog" aria-hidden="true" key = {datum.title}>
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="close-modal" data-bs-dismiss="modal"><img src="assets/img/close-icon.svg" alt="Close modal" /></div>
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-8">
                                                <div className="modal-body">
                                                    {/* <!-- Project details--> */}
                                                    <h2 className="text-uppercase">{t(datum.title)}</h2>
                                                    {/* <p className="item-intro text-muted">Lorem ipsum dolor sit amet consectetur.</p> */}
                                                    <img className="img-fluid d-block mx-auto" src={`${process.env.PUBLIC_URL}/assets/img/portfolio/${datum.img}`} alt="..." />
                                                    <p>{t(datum.content)}</p>
                                                    <ul className="list-inline">
                                                        {/* <li>
                                                            <strong>Client:</strong>
                                                            Threads
                                                        </li>
                                                        <li>
                                                            <strong>Category:</strong>
                                                            Illustration
                                                        </li> */}
                                                    </ul>
                                                    <button className="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                                        <i className="fas fa-xmark me-1"></i>
                                                        Close Project
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
            )
        }
        </>
    )
}