import '../css/styles.css';
import { useTranslation } from 'react-i18next';
import i18next from "../i18n";

const About = () => {
    const { t } = useTranslation();
    const clickHandler = (lang: string) => {
        i18next.changeLanguage(lang);
    }
    return(
        <section className="page-section" id="about">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">About</h2>
                    <h3 className="section-subheading text-muted">{t('activityHistory')}</h3>
                </div>
                <ul className="timeline">
                    <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src={`${process.env.PUBLIC_URL}/assets/img/about/fuc.jpg`} alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>March 31, 2023</h4>
                                <h4 className="subheading">{t('LogoPublish')}</h4>
                            </div>
                            </div>
                    </li>
                    <li className="timeline-inverted">
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/2.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>May 1, 2023</h4>
                                <h4 className="subheading">{t('psychoDevelop')}</h4>
                            </div>
                            </div>
                    </li>
                    {/* <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/3.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>May 2, 2023</h4>
                                <h4 className="subheading">{t('visionTechDevelop')}</h4>
                            </div>
                            </div>
                    </li> */}
                    <li className="timeline-inverted">
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/4.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>May 3, 2023</h4>
                                <h4 className="subheading">{t('researchSustainability')}</h4>
                            </div>
                            </div>
                    </li>
                    <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/3.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>May 4, 2023</h4>
                                <h4 className="subheading">{t('treeResearch')}</h4>
                            </div>
                            </div>
                    </li>
                    <li className="timeline-inverted">
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/4.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>May 17, 2023</h4>
                                <h4 className="subheading">{t('CaravanStanford')}</h4>
                            </div>
                            </div>
                    </li>

                    <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/3.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>May 22, 2023</h4>
                                <h4 className="subheading">{t('caravanShowcase')}</h4>
                            </div>
                            </div>
                    </li>
                    <li className="timeline-inverted">
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/4.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>May 22-25, 2023</h4>
                                <h4 className="subheading">{t('stanfordFeedback')}</h4>
                            </div>
                            </div>
                    </li>
                    <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/3.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>May 31, 2023</h4>
                                <h4 className="subheading">{t('consultingESG')}</h4>
                            </div>
                            </div>
                    </li>
                    {/* <li className="timeline-inverted">
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/4.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>June 19, 2023</h4>
                                <h4 className="subheading">{t('visitPatent')}</h4>
                            </div>
                            </div>
                    </li> */}
                    <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/3.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>June 21, 2023</h4>
                                <h4 className="subheading">{t('CaravanStanford2')}</h4>
                            </div>
                            </div>
                    </li>
                    {/* <li className="timeline-inverted">
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/4.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>July 7, 2023</h4>
                                <h4 className="subheading">{t('caravanWorkshop')}</h4>
                            </div>
                           </div>
                    </li> */}
                    <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/3.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>July 9, 2023</h4>
                                <h4 className="subheading">{t('caravanTeamDay')}</h4>
                            </div>
                            </div>
                    </li>
                    {/* <li className="timeline-inverted">
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/4.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>July 9, 2023</h4>
                                <h4 className="subheading">{t('studyPaper')}</h4>
                            </div>
                            </div>
                    </li> */}
                    <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/3.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>July 11, 2023</h4>
                                <h4 className="subheading">{t('drugFilterAR')}</h4>
                            </div>
                            </div>
                    </li>
                    <li className="timeline-inverted">
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/4.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>July 16, 2023</h4>
                                <h4 className="subheading">{t('researchEconomics')}</h4>
                            </div>
                            </div>
                    </li>
                    <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/3.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>July 23, 2023</h4>
                                <h4 className="subheading">{t('researchCometics')}</h4>
                            </div>
                            </div>
                    </li>
                    <li className="timeline-inverted">
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/4.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>October 1, 2023</h4>
                                <h4 className="subheading">{t('researchNYC')}</h4>
                            </div>
                            </div>
                    </li>
                    {/* <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/3.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>December 21, 2023</h4>
                                <h4 className="subheading">{t('caravanWorkshop2')}</h4>
                            </div>
                            </div>
                    </li> */}
                    <li className="timeline-inverted">
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/4.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>January 17, 2024</h4>
                                <h4 className="subheading">{t('researchLCA')}</h4>
                            </div>
                            </div>
                    </li>
                    <li>
                        <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/3.jpg" alt="..." /></div>
                        <div className="timeline-panel">
                            <div className="timeline-heading">
                                <h4>January 21, 2024</h4>
                                <h4 className="subheading">{t('researchCampWithStanford')}</h4>
                            </div>
                            </div>
                    </li>
                    <li className="timeline-inverted">
                        <div className="timeline-image">
                            <h4>
                                Be Part
                                <br />
                                Of Our
                                <br />
                                Story!
                            </h4>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}

export default About;