import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home.tsx';
import Booking from './pages/Booking.tsx';
import Team from './pages/Team.tsx';

const App: React.FC = () => (
    <BrowserRouter>
        <Routes>
            <Route path = "/" element = {<Home />} />
            <Route path = "/team" element = {<Team />} />
            <Route path = "/stanford" element = {<Booking />} />
        </Routes>
    </BrowserRouter>
)

export default App;