import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import '../css/styles.css';
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';

const StanfordImg = () => {

    return (
       <>
             <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={3}
        coverflowEffect={{
          rotate: 10,
          stretch: 0,
          depth: 100,
          modifier: 2,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination, Autoplay]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        className="mySwiper"
      >
                    <SwiperSlide>
                    <img className="ImageSize" src = {`${process.env.PUBLIC_URL}/assets/img/stanford/Stan_4.jpeg`} alt = "..."/>
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="ImageSize" src = {`${process.env.PUBLIC_URL}/assets/img/stanford/Stan_5.jpeg`} alt = "..."/>
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className="ImageSize" src = {`${process.env.PUBLIC_URL}/assets/img/stanford/Stan_6.jpeg`} alt = "..."/>
                    </SwiperSlide>
            </Swiper>
        </>
    )
}

export default StanfordImg;