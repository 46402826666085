export const toDataURL = (url: string) => {
    return fetch(`${process.env.PUBLIC_URL}/assets/files/`+url)
    .then((Response) => {
        return Response.blob();
    })
    .then((blob) => {
        return URL.createObjectURL(blob);
    });
};

export const Download = async (url: string, fileName?: string) => {
    const a = document.createElement("a");
    a.href = await toDataURL(url);
    a.download = fileName ?? "download";

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}